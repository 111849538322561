@import '../../assets/scss/variables.scss';

.dashboard-counts{

    display: flex;
    justify-content: center;

    .count{
        flex: 1;
        box-sizing: border-box;
        margin-right: 1rem;
        border-radius: $radiusSmall;

        &:last-child{
            margin-right: 0;
        }
        .numb{
            font-size: 56px;
            color: $secondaryColor;
            font-family: 'Barlow-Regular';
        }
        .subtitle{
            font-family: 'Barlow-SemiBold' !important;
        }

        .dblcount {
            width: 100%;
            .group {
                display: flex;
                flex-direction: row;
                align-items: baseline;
            }
        }

        .iconNumb {
            margin-left: .25em;
            font-size: 32px;
            color: $secondaryColor;
        }

        .count {
            padding: 0.5em;
            margin-top: 1em;
            flex: 0;
            width: 100%;
            border-radius: 0;
            background-color: rgba(255,255,255, 0.1);
            .numb {
                margin-right: 0.25em;
                color: #ff4b4b;
                font-size: 1rem;
            }
            .msubtitle {
                color: #ff4b4b;
                font-size: 1rem
            }
        }

    }
}
