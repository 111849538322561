@import '../../../../assets/scss/variables.scss';

.explore-edit-form{
  width: 500px !important;
  min-height: 250px;
  overflow: hidden;
  padding: 1rem;
  box-sizing: border-box !important;
  & > div{box-sizing:  border-box !important;}

  .form {
    display: flex;
    flex-direction: column;
    padding: 1em;

    .MuiFormLabel-root {
      color: white;
    }
  }

}
