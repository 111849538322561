@import '../../assets/scss/variables.scss';

.inbox-page {

}

.inbox-page-modal {
  &.modal {
    width: 100%;
    min-height: 50vh;
    padding: 1em !important;
    justify-content: flex-start;
    p {
      width: 100%;
      text-align: left;
      margin: 0;

      &.info-value {
        padding: 0.25rem 0;
      }

      &.info-content {
        min-height: 200px;
        background: $gridContent;
        padding: 0.5rem;
      }

    }
  }
  .info-label {
    font-weight: bold;
    color: $highlight;
    border-bottom: 1px solid $highlight;
  }
}
