@import '../../assets/scss/variables.scss';

.sidebar{
    width: 100%;
    height: 100%;

    .navbar-version {
        font-size: 0.6rem;
        font-style: italic;
    }

    .menu-items{
        width: fit-content;
        height: 300px;

        .logout-btn{
            width: fit-content;
            min-width: 0;
            font-size: 14px;
            padding: 0;
            padding-left: 1rem;
            margin: 0;
            color: $secondaryColor !important;
        }
    }
}
