@import '../../assets/scss/variables.scss';

.sub-info-form {
  .MuiInputLabel-outlined {
    color: $highlight !important;
  }
  .MuiInputAdornment-root {
    .MuiTypography-colorTextSecondary {
      color: $highlight !important;
    }
  }
  .MuiGrid-item + .MuiGrid-item {
    margin-top: 1em;
  }
  .MuiFormControl-root + .MuiFormControl-root {
    margin-right: 1em;
    margin-left: 1em;
  }

  .first-row {
    display: flex;
    align-items: center;
    button {
      height: 45px;
    }
  }
}
