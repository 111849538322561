@import '../../assets/scss/variables.scss';

.modal{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 0 !important;
    padding: 1rem;
    border-radius: $radiusRegular !important;

    p{
        font-size: 16px;
        text-align: center;
    }
    button{
        text-transform: capitalize;
    }
}