@import '../../assets/scss/variables.scss';

.light-link{
    text-decoration: none;
    color: $white;
    padding: .5rem 1rem;
    margin: 0;
    transition: color .35s;
    
    .light{
        margin-right: 5px;
    }
    .bold{
        font-weight: 600;
    }
    &:hover{
        color: $secondaryColor !important;
    }
}
.active{
    position: relative;
    &::before{
        content: "";
        position: absolute;
        width: 100%;
        height: 70%;
        left: 0;
        top: 15%;
        border-left: 4px solid $secondaryColor;
    }
}