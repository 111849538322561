@import '../../../assets/scss/variables.scss';

.login-form{
    .email, .password{
        border-radius: $radiusSmall !important;
        margin: .7rem 0;
        border-color: $secondaryColor;
        width: 100%;
        height: 50px !important;
        label+div{
            border-radius: $radiusLarge;
        }
        fieldset{
            border-color: $secondaryColor;
            height: 54px !important;
            border-radius: $radiusSmall !important;
            legend{ display: initial;}
            margin-top: 0;
        }
        input{
            color: $secondaryColor;
        }
    }
    .error{
        padding: .5rem;
        text-align: center;
        width: fit-content;
        border-radius: 4px;
        background: crimson;
        font-size: 14px;
        margin: 1rem 0 0;
    }
    .submit-btn{
        margin: 1.5rem 0 !important;
    }
}