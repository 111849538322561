@import '../../assets/scss/variables.scss';


.event-info{
    width: 800px !important;
    height: 600px;
    .info{
        padding: 1rem;
        height: 100% !important;
        .count-box{
            .subtitle{
                color: $white;
                span{
                    color: $secondaryColor;
                    margin-left: 1rem;
                }
            }
            .cats{
                span{
                    font-size: 12px;
                    margin-left: .3rem;
                }
            }
        }
    }
    .poster{
        img{
            width: 100%;
            height: 100%;
            object-fit: fill;
        }
    }
}