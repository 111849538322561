@import './app.scss';
@import './fonts.scss';
@import './variables.scss';

*{
    box-sizing: border-box !important;
}

body{
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-family: 'Barlow-Regular';
}

.box-title, .subtitle{
    font-size: 24px;
    font-family: 'Barlow-Medium' !important;
    text-transform: uppercase;
    width: 100% !important;
    text-align: center;
    display: block;
    clear: both;
    color: $secondaryColor;
}

.subtitle{
    font-size: 18px;
}

.link{
    font-family: 'Barlow-Medium' !important;
    font-size: 16px !important;
    text-transform: uppercase !important;
    color: $white !important;
}

input{
    font-family: 'Barlow-Regular' !important;
    font-size: 16px !important;
}

p{
    font-family: 'Barlow-Regular' !important;
    font-size: 14px !important;
}

.green{background: #84D754;}
.sky{background: #54D7D3;}
.blue{background: #5491D7;}
.purple{background: #9654D7;}
.violet{background: #D754B4;}
.red{background: #D75454;}
.orange{background: #D79E54;}
.yellow{background: #C6D754;}


// form input fields centralized styling
.input-dark{
    width: 100%;
    margin: 0;
    background: $gridContent;
    border-radius: $radiusSmall;
    height: 45px !important;

    label{
        color: $secondaryColor;
        text-transform: capitalize;
    }
    fieldset{
        height: 49px;
        margin-top: 0px;
        border-color: transparent;
    }
    input{
        color: $bgContent;
        padding: 0 1rem;
    }
    div{
        border-radius: $radiusSmall;
        height: 45px;
        &:before, &:after{
            display: none !important;
        }
    }
}
.input-textarea{
    height: 150px !important;

    textarea{
        font-size: 14px;
        padding: .5rem;
    }
    label{
        font-size: 14px;
    }
    fieldset{
        height: 150px !important;
        margin-top: 4px;
        border-color: transparent;
        legend{ display: none;}
    }
    div{
        border-radius: $radiusSmall !important;
        height: 150px !important;
        max-height: 150px !important;
        &:before, &:after{
            display: none !important;
        }
    }
}
.select-input{
    div{
        border-radius: $radiusSmall;
        height: 45px;
        max-height: 45px !important;
        padding-left: .5rem !important;
        padding-top: .6rem !important;
        padding-bottom: 0 !important;
        padding-right: 0 !important;

        &:before, &:after{
            display: none !important;
            border: none !important;
        }
    }
    &:active{
        div{
            padding: 0 !important;
        }
    }
}

.tag-select{
    width: 100%;
    margin: 0;
    background: $gridContent;
    border-radius: $radiusSmall;
    min-height: 45px !important;

    label{
        color: $secondaryColor;
        text-transform: capitalize;
    }
    fieldset{
        min-height: 49px;
        margin-top: 0px;
        border-color: transparent;
    }
    input{
        color: $bgContent;
        padding: 0 1rem;
    }
    div{
        border-radius: $radiusSmall;
        min-height: 45px;
    }
}

.full-tag-select-dropdown{
    margin-left: 5px;
    .static{
        color: inherit;
    }
    ul{
        li{
            .part-list-option{
                .color-box{
                    width: 8px;
                    height: 8px;
                    margin-right: 3px;
                    border-radius: 100%;
                    background: transparent;

                    &.green{background: #84D754;}
                    &.sky{background: #54D7D3;}
                    &.blue{background: #5491D7;}
                    &.purple{background: #9654D7;}
                    &.violet{background: #D754B4;}
                    &.red{background: #D75454;}
                    &.orange{background: #D79E54;}
                    &.yellow{background: #C6D754;}
                    &.none{background: transparent;}
                }
                .text{
                    width: fit-content !important;
                    height: fit-content !important;
                    font-family: 'Barlow-Regular';

                    &.bold{
                        font-family: 'Barlow-Medium';
                    }
                }
            }
        }
    }
}

// submit button for edit profile and add event form
.submit-btn, .cancel-btn{
    box-sizing: border-box !important;
    margin: 0 0 0 0 !important;
    width: 140px !important;
    background: $secondaryColor !important;
    color: $white !important;
    font-size: 16px;
    height: 45px;
    text-transform: uppercase !important;
    font-family: 'Barlow-SemiBold' !important;
    border-radius: $radiusSmall !important;
    &:hover{
        background: $secondaryColor;
    }
}
.cancel-btn{
    background: $bgContent !important;
    color: $eventCount !important;
    margin-right: 1rem !important;
}

// avatar and header upload button styling
.upload-btn{
    width: fit-content;
    padding: .5rem;
    margin: 0;
    background: $secondaryColor;
    text-transform: capitalize;
    cursor: pointer;
    border-radius: $radiusMedium;
    width: fit-content !important;
}

//custom designed chips
.chip{
    padding: .3rem 1rem;
    width: fit-content;
    display: block;
    float: left;
    height: 30px !important;
    margin: 0 .5rem .2rem 0 !important;
    cursor: pointer;
    border-radius: $radiusMedium;
    background: $secondaryColor !important;
    color: $gridMain;
    svg{
        color: $white !important;
    }

    &.int-chip{
        background: $white;
        color: $gridMain;
        &.checked{
            background: $secondaryColor;
            color: $white;
        }
    }
    &.tag{
        margin: 1rem .5rem 0 0 !important;
    }
}

.hide-scroll {
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
}
.hide-scroll::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
}

.edit-icon{
    color: $secondaryColor;
    cursor: pointer;
}
.del-icon{
    color: $red;
    cursor: pointer;
}

//custo table designed
.custom-table{
    thead{
        tr{
            th{
                color: $secondaryColor;
                font-family: 'Barlow-SemiBold';
                border-color: $secondaryColor;
                text-transform: uppercase;
                padding: 1rem !important;
            }
        }
    }
    tbody{
        tr{
            cursor: pointer;
            td{
                font-family: 'Barlow-Regular';
                border-color: $background;
                text-transform: capitalize;
                &.yes{
                    color: $secondaryColor;
                }
                &.no{
                    color: $red;
                }
            }
        }
    }
}
.MuiPickersCalendarHeader-dayLabel{
    color: #bbbbbb !important;
}
.full-tag-select-dropdown{
    margin-left: 5px;
    .static{
        color: inherit;
    }
    ul{
        li{
            .part-list-option{
                .text{
                    width: fit-content !important;
                    height: fit-content !important;
                    font-family: 'Barlow-Regular';

                    &.bold{
                        font-family: 'Barlow-Medium';
                    }
                }
            }
        }
    }
}