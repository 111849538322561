@import '../../../assets/scss/variables.scss';

.explore-user-event-form{
    width: 100%;

    .poster {
        min-width: 300px;
        min-height: 300px;
        background: $gridContent;
        border-radius: $radiusSmall;
        padding: 1rem;
        .head{
            width: 100%;
            height: 40px;
            .plus-icon{
                cursor: pointer;
                color: $secondaryColor;
            }
        }
        .poster-display {
            width: 300px;
            height: 350px;
            background-color: white;
            border-radius: $radiusSmall;
            object-fit: cover;
        }
        .save-poster-btn {
            margin-top: 1rem;
            padding: 0.25rem 0.75rem;
            font-size: .7rem;
            font-weight: bold;
            color: $highlight;
            background-color: $bgContainer;
            &:hover {
                background-color: $highlight;
                color: white;
            }
        }
    }

    .explore-header {
        background: $gridContent;
        border-radius: $radiusSmall;
        padding: 1rem;
        flex: 1;

        .head{
            width: 100%;
            height: 40px;
            .cursor-pointer {
                cursor: pointer;
                color: $secondaryColor;
            }
            #add-heading-explore {
                display: none;
            }
        }

        .header-marketing {
            width: 80%;
            position: absolute;
            top: 18%;
            left: 50%;
            font-size: 2rem;
            text-align: center;
            font-family: Barlow-Bold, sans-serif;
            text-transform: uppercase;
            z-index: 102;
            color: #fff;
            word-break: break-word;
            transform: translate(-50%, 0);

            textarea {
                width: 100%;
                height: 200px;
                outline: 0;
                text-align: center;
                font-size: 2rem !important;
                font-family: Barlow-Bold, sans-serif !important;
                border: none;
                background: transparent;
                color: white;
            }

        }

        .explore-header-img-wrap {
            position: relative;
            border-radius: 33px;

            img {
                width: 100%;
                height: 350px;
                border-radius: 33px;
                object-fit: cover;
            }

            .overlay {
                width: 100%;
                height: 350px;
                position: absolute;
                top: 0;
                background: #000303;
                opacity: 0.65;
                border-radius: 33px;
            }
        }

        .save-poster-btn {
            margin-top: 1rem;
            padding: 0.25rem 0.75rem;
            font-size: .7rem;
            font-weight: bold;
            color: $highlight;
            background-color: $bgContainer;
            &:hover {
                background-color: $highlight;
                color: white;
            }
        }

    }

    .wrap-explore-content {
        width: 100%;
        .events, .users{
            width: 100%;
            background: $gridContent;
            padding: 1rem;
            border-radius: $radiusSmall;
            .head{
                height: 40px;
                .plus-icon{
                    cursor: pointer;
                    color: $secondaryColor;
                }
            }
            .content{
                height: 500px;
                .del-icon{
                    color: $secondaryColor;
                    cursor: pointer;
                }
            }
        }
    }
}
