@import '../../assets/scss/variables.scss';

.modal{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 0 !important;
    margin: 0 !important;
    padding: 0 !important;

    &.dark{background-color: $background !important;}
    &.light{background-color: $white !important;}
    &.radius{border-radius: $radiusRegular !important;}
}