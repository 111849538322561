@import '../../assets/scss/variables.scss';

.explore-event-card{
    width: 90%;
    min-height: 70px;
    border-radius: $radiusRegular;
    padding: .5rem;

    &.ispast {
        opacity: 0.5;
    }

    .event-card-content{
        width: calc(100%);
        height: 100%;
        border-radius: 50px $radiusRegular $radiusRegular 50px;
        padding: .2rem;

        .title, .date, .owner{
            margin: 0;
            text-transform: capitalize;
            font-size: 11px;
            font-family: 'Barlow-Regular';
        }
        .title{
            width: 100%;
            font-size: 14px;
            font-family: 'Barlow-Medium';
        }
        .owner{
            width: 100%;
            font-size: 14px;
        }
    }
}
