@import '../../../assets/scss/variables.scss';

.discover-form{
    width: 500px !important;
    height: 450px;
    overflow: hidden;
    padding: 1rem;
    box-sizing: border-box !important;
    & > div{box-sizing:  border-box !important;}

    .checkbox-dark{
        border-radius: $radiusSmall;
        background: $gridContent;
        height: 45px;
        width: 100%;
        padding: .6rem;
        position: relative;
        font-weight: 600;
        cursor: pointer;
        text-transform: capitalize;

        label{
            color: $secondaryColor;
            cursor: pointer;
        }

        &.selected{
            background: $secondaryColor;
            label{
                color: $gridMain;
            }
            .icon{
                color: $gridMain;
            }
        }

        .icon{
            font-size: 16px;
            margin-right: 1rem;
            color: $secondaryColor;
        }
    }
    .submit-btn{
        margin: 1.5rem 0 !important;
    }
}