@import '../../assets/scss/variables.scss';

.snackbar{
    &.success{
        div{
            background: $secondaryColor;
            color: $eventCount;
            font-size: 14px;
            text-transform: initial;
        }
    }

    &.info{
        div{
            background: $gridContent;
            color: $white;
            font-size: 14px;
            text-transform: capitalize;
        }
    }

    &.error{
        div{
            background: $red;
            color: $white;
            font-size: 14px;
            text-transform: capitalize;
        }
    }
}