@import '../../assets/scss/variables.scss';

.discover-user-card{
    box-sizing: border-box !important;
    width: 22.7%;
    height: 160px;
    position: relative;
    border-radius: $radiusRegular;
    margin: 1rem .5rem;

    .discover-card-header{
        width: 100%;
        height: 100%;
        border-radius: $radiusRegular;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 999;
        object-fit: cover;
    }
    .discover-card-avatar{
        width: 50px;
        height: 50px;
        border-radius: 100%;
        position: absolute;
        top: -10%;
        left: 5%;
        z-index: 1000;
    }

    .actions{
        position: absolute;
        width: fit-content;
        bottom: 2%;
        right: 2%;
        z-index: 1000;
        .icon-btn{
            color: $secondaryColor;
            font-size: 20px;
            margin-right: .5rem;
            cursor: pointer;
            &:last-child{
                margin-right: 0;
            }
        }
    }
}