@import '../../assets/scss/variables.scss';

.user-info{
    border-radius: $radiusRegular;
    margin: 1rem 0;
    width: 400px;
    .avatar-box{
        height: 80px;
        width: 80px;
        &.large{
            width: 120px;
            height: 120px;
            .avatar{
                widows: 100%;
                height: 100%;
                border-radius: 100%;
            }
        }
        .avatar{
            widows: 100%;
            height: 100%;
            border-radius: 100%;
        }
    }
    .info-box{
        padding: .5rem;
        height: fit-content;

        h4{
            margin: 0;
            text-transform: capitalize;
            text-align: center;

            &.nickname{
                font-family: 'Barlow-SemiBold';
                font-size: 22px;
                color: $secondaryColor;
            }
            &.name{
                font-family: 'Barlow-Regular';
                font-size: 14px;
            }
        }
        .subtitle{
            color: $white;
            text-transform: capitalize;
            span{
                color: $secondaryColor;
                margin-left: .2rem;
            }
        }
        .bio{
            span{
                text-transform: initial;
            }
        }
        .submit-btn{
            margin-right: 0 !important;
            display: block;
            margin: 1rem auto !important;
        }
    }
}