@import '../../assets/scss/variables.scss';

.explore-user-card{
    width: 90%;
    height: 40px;
    border-radius: $radiusRegular;
    position: relative;
    margin: 0;

    .avatar{
        width: 50px;
        height: 50px;
        border-radius: 100%;
        position: absolute;
        left: 0px;
        top: -6px;
        cursor: pointer;
    }

    .user-card-content{
        width: calc(100% - 22% - (50px));
        position: absolute;
        top: 0;
        left: 60px;
        height: 40px;
        .name{
            margin: 0;
            text-transform: capitalize;
            font-size: 14px;
            font-family: 'Barlow-SemiBold';
        }
    }
}