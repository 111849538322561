@import '../../assets/scss/variables.scss';

.tag-form{
    width: 500px !important;
    height: 350px;
    overflow: hidden;
    padding: 1rem;
    box-sizing: border-box !important;
    & > div{box-sizing:  border-box !important;}

    .submit-btn{
        margin: 1.5rem 0 !important;
    }
}