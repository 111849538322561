$white: #ffffff;
$theme-color: #072638;
$primaryColor: #012537;
$secondaryColor: #03CDBF;
$bgColor: #fff;
$bgContainer: #F6F5F5;
$bgContent: #E7E7E7;
$borderLight: rgb(148, 147, 147);
$textColor: #2B2B2B;
$textSecondaryColor: #fff;
$highlight: #00D1BF;
$gridMain: #283654;
$gridContent: #31425F;
$background: #23304B;
$calendarRow: #00D5BF80;
$radiusFull: 40px;
$radiusLarge: 33px;
$radiusMedium: 23px;
$radiusRegular: 8px;
$radiusSmall: 5px;
$eventCount: #18243D;
$sidebar: #1a1a1a;
$red: #D75454;
$gridContentLight: #E7E7E7;