@import '../../../assets/scss/variables.scss';

.user-form{
    width: 100% !important;
    margin: 0 !important;
    padding: 2rem 1rem;
    .box-title{
        margin: 0 0 1.5rem 0;
    }
    .submit-btn{
        margin: 1.5rem 0 0 0 !important;
    }
}
