@import '../../assets/scss/variables.scss';

.tag-search{
    margin-bottom: 2rem;
    .input-dark{
        margin-right: 1rem;
    }
    .large{
        width: 170px !important;
    }
    .submit-btn{
        height: 42px;
    }
}