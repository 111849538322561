@import '../../../assets/scss/variables.scss';

.top-container{
    width: 830px !important;
    max-height: 100% !important;
    padding: 1rem;

    .add-event-form{
        position: relative;
        min-height: 600px;
        overflow: hidden;
        .title{
            margin: .3rem 0;
        }

        .back-btn{
            position: absolute;
            top: 1rem;
            left: 1rem;
            background: transparent;
            text-transform: capitalize;
            font-size: 14px;

            .icon{
                margin-right: .5rem;
                font-size: 16px;
            }
        }
        .header{
            h2{
                margin: 0 0 1rem;
                text-align: center;
            }
        }
        .tag-select{
            input{
                padding-left: 1rem !important;
            }
            &>div{
                border-radius: $radiusSmall;
                div{
                    padding: 0 !important;
                    border-radius: $radiusSmall;
                }
            }
            svg{
                color: $secondaryColor;
            }
            fieldset{
                border-color: transparent;
            }
            .chip{
                padding: 0rem !important;
                font-size: 12px;
                height: 30px;
                background: $secondaryColor;
                svg{
                    color: $eventCount;
                    width: 18px;
                }
            }
            .down-btn{
                display: none !important;
            }
        }
        .switch{
            color: $secondaryColor;
        }
        .time{
            position: relative;
            label{
                position: absolute;
                left: 5%;
                top: -30%;
                text-transform: capitalize;
                font-size: 12px;
            }
            label+div{
                margin-top: 0;
            }
            p{
                display: none;
            }
            svg{
                color: $secondaryColor !important;
            }
        }

        .tabs{
            min-height: 300px;
            .menu{
                width: 100%;
                min-height: 40px;
                max-height: 40px;
                button{
                    padding: 0;
                    min-width: 35%;
                    max-width: 40%;
                    min-height: 40px;
                    max-height: 40px;
                    text-transform: capitalize;
                    border-radius: $radiusSmall $radiusSmall 0 0;
                    font-size: 16px !important;
                    &.select-dark{
                        background: $gridMain;
                        color: $white;
                        font-size: 12px !important;
                    }
                }
            }
            .indicator{
                display: none !important;
            }
            .section{
                border-radius: $radiusSmall;
                min-height: 250px;
                padding: 1.5rem 1rem 1rem 1rem;
            }
        }
    }
    .right-side-box{
        padding: 1rem 0 0 0;
    }
    .subtitle{
        text-align: left !important;
        margin-left: 1rem;
    }
    .input-textarea{
        width: 100% !important;
        display: block !important;
        margin: auto;
        &>div{
            width: 100%;
        }
    }
    .poster-box{
        height: 430px !important;
        width: 315px !important;
        margin: 0 0 1rem 0;
        border-radius: $radiusSmall;
        position: relative;
        &:hover{
            .upload-icon{
                opacity: 1;
            }
        }

        .upload-icon{
            width: fit-content !important;

            img{
                width: 50px;
                height: 50px;
                cursor: pointer;
            }
            input{
                display: none;
            }
        }

        .absolute{
            width: fit-content !important;
            position: absolute;
            top: calc(85% - 50px);
            left: 50%;
            transform: translate(-50%, 0);
            opacity: 1;
            transition: opacity .35s;
            z-index: 1021 !important;

            img{
                width: 40px;
                height: 40px;
                cursor: pointer;
            }
            input{
                display: none;
            }
        }
    }
    .description-box{
        width: 95%;
    }
}

.MuiPickersCalendarHeader-iconButton {
    color: #00D1BF !important;
}

.poster-display{
    width: 315px;
    height: 430;
    border-radius: $radiusSmall;
    z-index: 1020;
}
#add-event-image{
    display: none;
}
.mobile-poster-btn{
    height: 40px !important;
    font-size: 14px;
    width: 100%;
    text-transform: capitalize !important;
    border-radius: $radiusLarge !important;
    cursor: pointer !important;
    padding: .45rem .75rem !important;
    box-sizing: border-box;
    color: inherit !important;
    border: 2px dotted $secondaryColor;
    text-align: center;
    margin: 1rem 0;
}

@media only screen and (max-width: 600px){
    .top-container{
        width: 100% !important;

        .add-event-form{
            position: relative;
            min-height: 400px;
            max-height: 1000px;
            padding: 0;
            overflow: visible !important;

            .back-btn{
                position: absolute;
                top: -.2rem;
                left: 0;
                background: transparent;
                text-transform: capitalize;
                font-size: 14px;

                .icon{
                    margin-right: .5rem;
                    font-size: 16px;
                }
            }

            .tabs{
                width: 100%;
                min-height: 300px;
                height: auto;
                margin-top: 1rem;
                .menu{
                    button{
                        min-width: 40%;
                        max-width: 45%;
                        font-size: 14px !important;
                    }
                }
                .section{
                    min-height: 250px;
                }
            }
        }
        .poster-box{
            height: 430px !important;
            width: 300px !important;
        }
        .description-box{
            width: 100%;
        }
    }
    .poster-display{
        width: 100%;
        height: 100%;
        border-radius: $radiusMedium;
        z-index: 1020;
    }
}

@media only screen and (min-width: 600px) and (max-width: 1080px){
    .top-container{
        width: 700px !important;
        padding: 1rem;
        .add-event-form{
            position: relative;
            min-height: 500px;
            overflow: hidden;
            padding: 0 1rem 0 0;
        }
        .poster-box{
            height: 430px !important;
            width: 300px !important;
        }
        .description-box{
            width: 100%;
        }
    }
    .poster-display{
        width: 100%;
        height: 100%;
        border-radius: $radiusMedium;
        z-index: 1020;
    }
}
