@import '../../assets/scss/variables.scss';

.user-listing{
    width: 100% !important;
    margin-top: 2rem;
    max-height: 550px;
    overflow: scroll;

    .avatar{
        width: 35px;
        height: 35px;
    }
}
.listing-pagination{
    width: fit-content;
}