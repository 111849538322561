@import './variables.scss';

.app{
    min-height: 100vh;

    .app-content{
        min-height: calc(100vh);
        padding: 2rem 2rem;
        box-sizing: border-box !important;
    }
}
