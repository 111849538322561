@import '../../../assets/scss/variables.scss';

.interests{
    width: 100%;
    height: 60px;
    .parent-box{
        width: 150px !important;
        min-height: 60px;
        cursor: pointer;
        background: $white;
        color: $gridMain;
        border-radius: $radiusSmall;
        margin: 0 1rem;
        font-size: 18px;
        &.checked{
            background: $secondaryColor;
            color: $white;
        }
    }
}