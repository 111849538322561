@import '../../assets/scss/variables.scss';

.image-cropper{
    &.header{
        width: 1050px;
    }
    &.profile{
        width: 500px;
    }
    &.add-event{
        width: 1050px;
    }
    .crop-box{
        position: relative !important;
        width: 1200px !important;
        height: 680px !important;
    }
    .crop-actions{
        width: 300px;
        padding: .5rem;
        .crop-btn, .cancel-btn{
            background: $secondaryColor;
            color: $white;
            padding: .25rem 1rem;
            text-transform: capitalize;
            border-radius: $radiusSmall;
        }
        .cancel-btn{
            background: $gridContentLight;
            color: $gridMain;
            margin-right: 1rem;
        }
    }
}