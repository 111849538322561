@import '../../assets/scss/variables.scss';

.search-bar{
    width: 500px;
    margin: 1rem 0 0 0;
    background: $gridContent;
    border-radius: $radiusSmall;
    height: 50px !important;
    padding: 0 1rem 0 0;

    .search-input{
        width: 90%;
        margin: 0;
        height: 45px !important;

        label{
            color: $secondaryColor;
            text-transform: capitalize;
        }
        fieldset{
            height: 49px;
            margin-top: 0px;
            border-color: transparent !important;
        }
        input{
            color: $bgContent;
            padding: 0 1rem;
        }
        div{
            border-radius: $radiusSmall;
            height: 45px;
        }
    }
    .search-icon{
        color: $secondaryColor;
    }
}